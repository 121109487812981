































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
interface dataBanner {
  id: number;
  imagen: string;
  imagen_mobile: string;
  link: string;
}

@Component({
  components: {},
})
export default class General extends Vue {
  private nombreWeb: any = "";
  private aActividades: any = [];
  private ultimasActividades: any = [];
  private currentPage = 1;
  private total = 0;
  private slideIndex: number = 1;
  private aBanners: Array<dataBanner> = [];

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 8)) {
      this.getInfoActividad();
    }
  }

  @Watch("$refs")
  private refsWatch(newValue: any) {}

  private mounted() {
    this.getInfoActividad();
    this.autoscroll();
    this.getBanners();
  }

  private getBanners() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("slider/actividades")
      .then((res) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.aBanners = res.data;
        for (let index = 0; index < this.aBanners.length; index++) {
          this.aBanners[index].imagen = base + this.aBanners[index].imagen;
          if (
            this.aBanners[index].imagen_mobile &&
            this.aBanners[index].imagen_mobile != ""
          ) {
            this.aBanners[index].imagen_mobile =
              base + this.aBanners[index].imagen_mobile;
          } else {
            this.aBanners[index].imagen_mobile = this.aBanners[index].imagen;
          }
          if (!this.aBanners[index].link) {
            this.aBanners[index].link = "#";
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.aBanners = [];
      });
  }

  private getInfoActividad() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("actividades/general?pagina=" + this.currentPage)
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        // console.log(res.data);
        this.ultimasActividades = res.data.ultimas_actividades;
        this.aActividades = res.data.actividades;
        // console.log(this.aActividades);
        this.total = res.data.total;
        // console.log(this.ultimasActividades);

        for (let i = 0; i < this.aActividades.length; i++) {
          if (this.aActividades[i].foto) {
            this.aActividades[i].foto = base + this.aActividades[i].foto;
          } else {
            this.aActividades[i].foto = require("@/assets/logos/logo300.webp");
          }
        }

        for (let i = 0; i < this.ultimasActividades.length; i++) {
          if (this.ultimasActividades[i].foto) {
            this.ultimasActividades[i].foto =
              base + this.ultimasActividades[i].foto;
          } else {
            this.ultimasActividades[
              i
            ].foto = require("@/assets/logos/logo300.webp");
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private formatFechaCard(fecha: any) {
    let date = fecha.split(" ", 2);
    let newDate = date[0].split("-");
    return newDate[2] + "-" + newDate[1] + "-" + newDate[0];
  }

  private irActividad(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "PublicacionAct" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "PublicacionAct",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    if (slides.length > 0) {
      for (i = 0; i < slides.length; i++) {
        (slides[i] as any).style.display = "none";
      }

      (slides[this.slideIndex - 1] as any).style.display = "block";
    }
  }

  private plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  private autoscroll() {
    this.plusSlides(1);
    setTimeout(this.autoscroll, 9000);
  }

  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }
  // Fin Funcio
}
